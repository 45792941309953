import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import slugify from 'slugify'
import mediaQuery from 'src/assets/styles/mediaQuery'
import Seo from 'src/components/global/Seo.js'

import TitleH1 from 'src/components/global/texts/TitleH1.js'
import BackLink from 'src/components/global/buttons/BackLink.js'
import typographySizes from 'src/assets/styles/typographySizes.js'
import BarText from 'src/components/global/texts/BarText.js'
import SmallTitle from 'src/components/global/texts/SmallTitle.js'

import colors from 'src/assets/styles/colors'
import MainWrapper from 'src/components/global/MainWrapper.js'
import Lines from 'src/components/global/lines/Lines'
import distances from '../assets/styles/distances'
import SmallLink from 'src/components/global/buttons/SmallLink.js'

const StyledMain = styled.main`
  background-color: ${colors.black};
  width: 100vw;
`

const Header = styled.header`
  width: 100%;
  /* overflow: hidden; */
  position: relative;
  display: flex;
  padding-bottom: ${distances.mBottomPage}rem;
  /* background-color: rosybrown; */
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    padding-bottom: ${distances.mBottomPage / 2}rem;
  }
`
const PersonName = styled(TitleH1)`
  margin-top: ${distances.mFromTop}rem;
  margin-bottom: 20rem;
  color: ${colors.mainColor};
  margin-left: 20rem;
  word-wrap: break-word;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.m}rem;
    margin-top: ${distances.mFromTop / 2}rem;
    margin-bottom: 50rem;
  }
`

const CoverCol = styled.div`
  width: 33.33%;
  margin-top: ${distances.mFromTop}rem;
  position: sticky;
  top: ${distances.mFromTop}rem;
  align-self: flex-start;
  ${PersonName} {
    display: none;
  }
  button:first-of-type {
    display: none;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    position: relative;
    top: 0;
    display: flex;
    flex-direction: column;
    margin-top: calc(${distances.mFromTop}rem + 35rem);
    button:first-of-type {
      display: flex;
      align-self: flex-end;
    }
    button:last-of-type {
      display: none;
    }
    ${PersonName} {
      display: inline-block;
    }
  }
`
const MainCol = styled.div`
  width: calc(41.66% - 20rem);
  margin-left: 0;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    ${PersonName} {
      display: none;
    }
  }
`

const Description = styled(BarText)`
  width: 60%;
  margin-left: 20%;
  margin-top: 50rem;
  margin-bottom: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 60rem);
    margin-left: 20rem;
  }
`

const SocialLinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50rem;
  margin-left: 20rem;
`

const SocialLink = styled(SmallLink)`
  text-transform: uppercase;
  margin-right: 50rem;
  margin-bottom: 20rem;
`

const StyledSmallTitle = styled(SmallTitle)`
  margin-bottom: 20rem;
  margin-left: 0rem;
`

const InformationLink = styled(Link)`
  position: relative;
  color: ${colors.white};
  font-size: ${typographySizes.s}rem;
  font-weight: 400;
  line-height: 1.5;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  span {
    color: ${colors.mainColor};
    font-size: ${typographySizes.s}rem;
    font-weight: 400;
    display: inline-block;
    margin: 0 10rem;
  }
  :last-of-type {
    span {
      display: none;
    }
  }
  :hover {
    color: ${colors.mainColor};
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`

const InformationRow = styled.div`
  margin-bottom: 50rem;
  margin-left: 20rem;
  width: calc(100% - 40rem);
  :last-of-type {
    margin-bottom: 0;
  }
`

const ImageOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.mainColor};
  mix-blend-mode: screen;
`

const ImageWrapper = styled.div`
  position: absolute;
  transform: rotate(0deg);
  margin-bottom: 20rem;
  width: 100%;
  top: 0;
  visibility: hidden;
  opacity: 0;
  &:nth-of-type(1) {
    position: relative;
    visibility: visible;
    opacity: 1;
    transform: rotate(3deg);
  }
  &:nth-of-type(2) {
    top: 20rem;

    ${ImageOverlay} {
      mix-blend-mode: multiply;
    }
  }
  &:nth-of-type(3) {
    top: 0rem;
    right: 30rem;
  }
  &:nth-of-type(4) {
    top: 0rem;
    left: 30rem;

    ${ImageOverlay} {
      mix-blend-mode: multiply;
    }
  }

  &:nth-of-type(2):hover {
    z-index: 5;
  }
  &:nth-of-type(3):hover {
    z-index: 5;
  }
  &:nth-of-type(4):hover {
    z-index: 5;
  }
`

const ImagesWrapper = styled.div`
  width: calc(100% - 80rem);
  margin: 0 40rem;
  position: relative;

  :hover {
    ${ImageWrapper}:nth-of-type(2) {
      visibility: visible;
      opacity: 1;
      transform: rotate(-3deg);
      transition: visibility 0s, opacity 0.01s 0.15s linear,
        transform 0.5s 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
    ${ImageWrapper}:nth-of-type(3) {
      visibility: visible;
      opacity: 1;
      transform: rotate(6deg);
      transition: visibility 0s, opacity 0.01s 0.3s linear,
        transform 0.5s 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
    ${ImageWrapper}:nth-of-type(4) {
      visibility: visible;
      opacity: 1;
      transform: rotate(-6deg);
      transition: visibility 0s, opacity 0.01s 0.45s linear,
        transform 0.5s 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`

const RecruitmentLayout = ({ data }) => {
  return (
    <>
      <Seo
        title={
          data.strapiTeams.SEO
            ? data.strapiTeams.SEO.Title
            : data.strapiTeams.Name
        }
        description={
          data.strapiTeams.SEO
            ? data.strapiTeams.SEO.Description
            : data.strapiTeams.Description
        }
        image={
          data.strapiTeams.SEO
            ? data.strapiTeams.SEO.Img.localFile.publicURL
            : data.strapiTeams.Images[0].localFile.publicURL
        }
      />

      <StyledMain>
        <MainWrapper>
          <Header>
            <Lines leftMargin leftCenter right rightMargin />
            <CoverCol>
              <BackLink>powrót</BackLink>
              <PersonName>{data.strapiTeams.Name}</PersonName>
              <ImagesWrapper>
                {data.strapiTeams.Images.map(img => (
                  <ImageWrapper>
                    <Img fluid={img.localFile.childImageSharp.fluid} />
                    <ImageOverlay />
                  </ImageWrapper>
                ))}
              </ImagesWrapper>

              <BackLink>powrót</BackLink>
            </CoverCol>
            <MainCol>
              <PersonName>{data.strapiTeams.Name}</PersonName>
              <Description>{data.strapiTeams.Description}</Description>
              <SocialLinkWrapper>
                {data.strapiTeams.SocialMedia.map(pos => (
                  <SocialLink to={pos.Link} target="_blank">
                    {pos.Name}
                  </SocialLink>
                ))}
              </SocialLinkWrapper>

              {data.strapiTeams.Mastering[0] ? (
                <InformationRow>
                  <StyledSmallTitle>Mastering:</StyledSmallTitle>
                  {data.strapiTeams.Mastering.map(Album => (
                    <InformationLink
                      to={`/albums/${slugify(Album.Name, {
                        lower: true,
                        strict: true,
                      })}`}
                    >
                      {Album.Name} <span>/</span>
                    </InformationLink>
                  ))}
                </InformationRow>
              ) : null}
              {data.strapiTeams.Mix[0] ? (
                <InformationRow>
                  <StyledSmallTitle>MIX:</StyledSmallTitle>
                  {data.strapiTeams.Mix.map(Album => (
                    <InformationLink
                      to={`/albums/${slugify(Album.Name, {
                        lower: true,
                        strict: true,
                      })}`}
                    >
                      {Album.Name} <span>/</span>
                    </InformationLink>
                  ))}
                </InformationRow>
              ) : null}
              {data.strapiTeams.Realizacja[0] ? (
                <InformationRow>
                  <StyledSmallTitle>Realizacja:</StyledSmallTitle>
                  {data.strapiTeams.Realizacja.map(Album => (
                    <InformationLink
                      to={`/albums/${slugify(Album.Name, {
                        lower: true,
                        strict: true,
                      })}`}
                    >
                      {Album.Name} <span>/</span>
                    </InformationLink>
                  ))}
                </InformationRow>
              ) : null}
            </MainCol>
          </Header>
        </MainWrapper>
      </StyledMain>
    </>
  )
}

export const query = graphql`
  query TeamLayout($id: String!) {
    strapiTeams(id: { eq: $id }) {
      SEO {
        Description
        Img {
          localFile {
            publicURL
          }
        }
        Title
      }
      Name
      Images {
        localFile {
          publicURL
          childImageSharp {
            fluid(
              quality: 100
              grayscale: true
              traceSVG: { background: "#000000", color: "#FF3D00" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      Description
      Mastering {
        Name
      }
      Mix {
        Name
      }
      Realizacja {
        Name
      }
      SocialMedia {
        Link
        Name
      }
    }
  }
`

export default RecruitmentLayout
